<template>
    <div style="font-size: 12px">
        <div class="bg-white min-h-screen text-black">
            <div class="grid grid-cols-12 h-full gap-5">
                <div class="col-span-1 relative flex  justify-center">
                    <div class="absolute transform -rotate-90 whitespace-nowrap text-4xl top-56">Invoice <span class="text-red-600 ml-5">#{{invoice}}</span></div>
                </div>
                <div class="col-span-11 mr-5">
                    <div v-if="company" class="flex border-b-2 border-red-600 pb-2">
                        <div>
                            <img :src="imgURL+'/public/img/company-logo.png'" width="80" alt="">
                        </div>
                        <div>
                            <div class="text-2xl font-bold text-red-600">{{company.name}}</div>
                            <div class="text-base italic font-semibold" style="color: blue;">{{company.tagline}}</div>
                            <div>{{company.companyAddress.address}} {{company.companyAddress.city.name}} {{company.companyAddress.postal_code}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-5 mt-3">
                        <div class="col-span-3">
                            <div class="text-red-600">Date</div>
                            <div>{{getDate(paymentDate || date)}}</div>
                            <div class="text-red-600">Customer PO</div>
                            <div v-if="customerPO">{{customerPO}}</div>
                            <div v-else>--</div>
                            <div class="text-red-600">Term Of Payment</div>
                            <div>{{paymentTerm}}</div>
                            <div class="text-red-600">Paid In Full</div>
                            <div>{{paymentMethod}}</div>
                            <div class="text-red-600">Ship Via</div>
                            <div>{{shipVia}}</div>
                        </div>
                        <div id="test" class="col-span-9">
                            <div class="grid grid-cols-2 gap-5">
                                <div v-if="billTo.customer" class="col-span-1">
                                    <div class="text-red-600">Bill To</div>
                                    <div class="font-semibold">{{billTo.parent}}</div>
                                    <div>{{billTo.customer.address.street}} {{billTo.customer.address.street2}}</div>
                                    <div>{{billTo.customer.address.subdistrictName}} {{billTo.customer.address.cityName}} {{billTo.customer.address.zip}}</div>
                                    <div>{{billTo.customer.address.provinceName}}</div>
                                    <div>Phone: 
                                        <span v-if="billTo.phone">{{billTo.phone.phone || billTo.phone.mobile}}</span>
                                    </div>
                                    <div v-if="billTo.attn" class="mt-3 font-semibold italic">Attn: {{billTo.attn}}</div>
                                </div>
                                <div v-if="shipTo" class="col-span-1">
                                    <div class="text-red-600">Ship To</div>
                                    <div class="font-semibold">{{shipTo.parent}}</div>
                                    <div>{{shipTo.customer.address.street}} {{shipTo.customer.address.street2}}</div>
                                    <div>{{shipTo.customer.address.subdistrictName}} {{shipTo.customer.address.cityName}} {{shipTo.customer.address.zip}}</div>
                                    <div>{{shipTo.customer.address.provinceName}}</div>
                                    <div>Phone:
                                        <span v-if="shipTo.phone">{{shipTo.phone.phone || shipTo.phone.mobile}}</span>
                                    </div>
                                    <div v-if="shipTo.attn" class="mt-3 font-semibold italic">Attn: {{shipTo.attn}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 8%">Quantity</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 60%">Description</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 14%">Unit Price</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 8%">Discount</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50 text-right" style="width: 10%">Total</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y">
                                <tr v-for="(item) in items" :key="item.idx">
                                    <td class="px-2 py-1">{{item.qty}}</td>
                                    <td class="px-2 py-1">
                                        {{item.name}}
                                        <div v-if="pos">
                                            <ul>
                                                <li v-for="(sn, s) in item.serialNumber" :key="s">SN: {{sn.sn}}</li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.unitPrice)}}</td>
                                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US').format(item.discount.value)}}</td>
                                    <td class="px-2 py-1 text-right">Rp{{Intl.NumberFormat('en-US').format(item.subTotal)}}</td>
                                </tr>
                                <tr v-for="a in addRow" :key="a">
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="text-right py-1">Subtotal <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(subTotal)}}</td>
                                </tr>
                                <tr v-if="discount">
                                    <td colspan="4" class="text-right py-1">Discount <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(discount)}}</td>
                                </tr>
                                <tr v-if="taxValue">
                                    <td colspan="4" class="text-right py-1">Tax 11% <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(taxValue)}}</td>
                                </tr>
                                <tr v-if="totalHandling">
                                    <td colspan="4" class="text-right py-1">Shipping & Handling <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right px-3">{{Intl.NumberFormat('en-US').format(totalHandling)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Total <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(grandTotal)}}</td>
                                </tr>
                                <tr v-for="(py) in payments" :key="py.paymentDate">
                                    <td colspan="4" class="text-right font-semibold py-1 italic">Paid On {{getPaidDate(py.paymentDate)}}<span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(py.amount)}}</td>
                                </tr>
                                <tr class="border-red-600">
                                    <td colspan="4" class="text-right font-semibold py-1">Amount Due <span class="ml-5">Rp</span></td>
                                    <td class="py-1 text-right font-semibold px-3">{{Intl.NumberFormat('en-US').format(amountDue)}}</td>
                                </tr>
                                <tr class="border-red-600"></tr>
                            </tbody>
                        </table>
                        <div v-if="bank">
                            <div class="mt-1">
                                All Checks must be cross and addressed to:
                            </div>
                            <div>
                               {{bank.name}}, KCP {{bank.kcp}}
                            </div>
                            <div>
                                A/C Name: <span class="font-semibold">{{bank.accountName}}</span>
                            </div>
                            <div class="flex justify-between">
                                <div>
                                    A/C #: <span class="font-semibold">{{bank.accountNumber}} (IDR) </span>
                                </div>
                                <div class="text-red-600">Thank you for your business!</div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mt-1">
                                All Checks must be cross and addressed to:
                            </div>
                            <div>
                                Bank Central Asia, KCP A. Yani 2, Bandung
                            </div>
                            <div>
                                A/C Name: <span class="font-semibold">PT. DUTA PERSADA INSTRUMENT</span>
                            </div>
                            <div class="flex justify-between">
                                <div>
                                    A/C #: <span class="font-semibold">8090-800-222 (IDR) </span>
                                </div>
                                <div class="text-red-600">Thank you for your business!</div>
                            </div>
                        </div>
                        <div class="mt-3 border p-5 flex items-center justify-between">
                            <img src="@/assets/kyowa.png" width="100" alt="">
                            <img src="@/assets/lutron.png" width="100" alt="">
                            <img src="@/assets/brunton.png" width="100" alt="">
                            <img src="@/assets/estwing.png" width="100" alt="">
                            <img src="@/assets/atlas.png" width="100" alt="">
                        </div>
                        <div class="flex mt-3">
                            <div>
                                <table>
                                    <tr>
                                        <td class="text-red-600">Tel</td>
                                        <td class="pl-3">022.7106162</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Fax</td>
                                        <td class="pl-3">022.7274932</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="ml-10">
                                <table v-if="company">
                                    <tr>
                                        <td class="text-red-600">Email</td>
                                        <td class="pl-3">{{company.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Web</td>
                                        <td class="pl-3">{{company.website}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { moment } from '../moment'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            toBillName: '',
            billTo: {
                customer: {
                    address: {
                        street: '',
                        street2: ''
                    },
                    contact: {
                        phone: ''
                    },
                },
                displayName: '',
                parent: '',
                attn: '',
            },
            shipTo: {
                customer: {
                    address: {
                        street: '',
                        street2: ''
                    },
                    contact: {
                        phone: ''
                    },
                },
                displayName: '',
                parent: '',
                attn: '',
            },
            paymentMethod: '',
            paymentTerm: '',
            shipVia: '',
            phone: '',
            cellPhone: '',
            fax: '',
            email: '',
            attn: '',
            date: '',
            paymentDate: '',
            invoice: '',
            customerPO: '',
            items: [],
            subTotal: '',
            discount: '',
            tax: '',
            taxValue: '',
            shipmentCost: 0,
            additionalChargers: 0,
            grandTotal: 0,
            amountDue: 0,
            offerConditions: '',
            type: '',
            downPayments: [],
            payments: [],
            bank: '',
            pos: ''
        }
    },
    computed: {
        ...mapGetters({
            company: 'auth/company'
        }),
        addRow () {
            let row = this.items.length
            let t = 0
            if(row < 10) {
                t = 10 - row
                return t
            } else {
                return t
            }
        },
        totalHandling () {
            let t = 0
            t = this.shipmentCost + this.additionalChargers
            return t
        },
    },
    methods: {
        getDate (d) {
            const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Des']
            const date = new Date(d)
            let dd = date.getDate()
            let mm = month[date.getMonth()]
            let yy = date.getFullYear()
            dd = checkTime(dd)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return `${dd}-${mm}-${yy}`
        },
        getPaidDate (d) {
            return moment(d)
        }
    },
    watch: {
        data: {
            handler (val) {
                if(val) {
                    let invoice = val.invoice
                    this.payments = val.invoice.payments
                    let payment = val.payments[0]
                    this.customerPO = val.invoice.customerPO
                    if(val.invoice.payments) {
                        let d = this.payments.slice(-1)
                        if(d.length > 0) {
                            this.paymentDate = d[0].paymentDate
                        }
                    }
                    if(invoice.billTo) {
                        this.billTo = invoice.billTo
                    }
                    if(invoice.shipTo) {
                        this.shipTo = invoice.shipTo
                    }
                    this.type = invoice.type
                    this.invoice = invoice.invoiceNo
                    this.date = invoice.confirmDate
                    this.items = invoice.items
                    this.subTotal = invoice.total
                    if(invoice.type == 'Regular') {
                        this.grandTotal = invoice.total +  invoice.tax.value + invoice.shipping.shipmentCost + invoice.totalAdditionalCharges - invoice.discount.value
                        this.amountDue = invoice.amountDue

                    } else {
                        let dp = 0
                        for (let i = 0; i < invoice.payments.length; i++) {
                            const el = invoice.payments[i];
                            dp += el.amount
                        }
                        this.grandTotal = invoice.grandTotal + invoice.downPayments[0].amount
                        this.amountDue = invoice.total - dp - invoice.discount.value
                    }
                    if(invoice.discount) {
                        this.discount = invoice.discount.value
                    }
                    if(invoice.tax) {
                        this.taxValue = invoice.tax.value
                    }
                    if(invoice.shipping) {
                        this.shipmentCost = invoice.shipping.shipmentCost
                    }
                    if(invoice.totalAdditionalCharges) {
                        this.additionalChargers = invoice.totalAdditionalCharges
                    }
                    if(invoice.paymentTerm) {
                        this.paymentTerm = invoice.paymentTerm.code
                    } else {
                        this.paymentTerm = '--'
                    }
                    if(payment) {
                        this.paymentMethod = payment.journal
                    } else {
                        this.paymentMethod = '--'
                    }
                    if(invoice.bank) {
                        this.bank = invoice.bank
                    }
                    if(invoice.shipVia) {
                        this.shipVia = invoice.shipVia.name
                    }
                    if(invoice.pos) {
                        this.pos = true
                    }
                    this.downPayments = invoice.downPayments
                }
            },
            deep: true
        }
    }
}
</script>