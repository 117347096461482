<template>
    <div class="text-left p-2" style="width: 75mm;">
        <div class="flex items-center text-left border-b-2 border-black pb-1 mb-1">
            <div class="mr-1">
                <img :src="imgURL+'/public/img/company-logo.png'" alt="" width="70">
            </div>
            <div>
                <div class="font-semibold font-sans tracking-tight" style="font-size: 12px">PT. DUTA PERSADA Instruments</div>
                <div class="italic font-semibold" style="font-size: 9px">Electronic Test & Measuring Instruments</div>
                <div style="font-size: 8px">Jl. Jendral Ahmad Yani 221-223 Ruko Segitiga Mas Kosambi Block B16, Bandung 40113</div>
            </div>
        </div>
        <div class="flex justify-between border-b-2 border-dashed border-black pb-1 mb-1">
            <div class="font-semibold" style="font-size: 12px">INVOICE: {{invoice}}</div>
            <div class="flex items-center"><span class="mr-1" style="font-size: 9px">Crated by</span><span class="font-semibold" style="font-size: 10px"> {{user}}</span></div>
        </div>
        <div class="border-b-2 border-dashed border-black pb-1 mb-1" style="font-size:  12px">
            <div>Tgl: {{this.getDate(date)}}</div>
            <div class="font-semibold">Nama Pembeli: {{customerName}}</div>
            <div>No. Telp: {{noTelp}}</div>
        </div>
        <div class="grid grid-cols-12 border-b-2 border-dashed border-black pb-1 mb-1" style="font-size: 12px">
            <div class="col-span-1 pr-1">Qty</div>
            <div class="col-span-5 pr-1">Barang</div>
            <div class="col-span-3 pr-1 text-center">Harga</div>
            <div class="col-span-3 text-center">Total</div>
        </div>
        <div v-for="(item, i) in items" :key="i" class="grid grid-cols-12 pb-0.5" style="font-size: 11px">
            <div class="col-span-1 pr-1">{{item.qty}}</div>
            <div class="col-span-5 pr-1"><span class="capitalize">
                {{item.brand}}</span> {{item.model}}
                <ul>
                    <li v-for="(sn, i) in item.serialNumber" :key="i">SN: {{sn.sn}}</li>
                </ul>
            </div>
            <div class="col-span-3 pr-1 text-right">{{Intl.NumberFormat('en-US').format(item.unitPrice)}}</div>
            <div class="col-span-3 text-right">{{Intl.NumberFormat('en-US').format(item.total)}}</div>
        </div>
        <div class="border-b border-dashed border-black w-full mb-1"></div>
        <div class="grid grid-cols-6 font-semibold">
            <div class="col-span-2"></div>
            <div class="col-span-2" style="font-size: 12px">
                <div class="mr-2">Subtotal</div>
            </div>
            <div class="col-span-2 text-right" style="font-size: 12px">
                <div>{{Intl.NumberFormat('en-US').format(subtotal)}}</div>
            </div>
        </div>
        <div class="grid grid-cols-6 mb-1">
            <div class="col-span-2"></div>
            <div class="col-span-2" style="font-size: 12px">
                <div class="mr-2">Discount</div>
            </div>
            <div class="col-span-2 text-right" style="font-size: 12px">
                <div>{{Intl.NumberFormat('en-US').format(discount)}}</div>
            </div>
        </div>
        <div class="grid grid-cols-6 mb-1">
            <div class="col-span-2"></div>
            <div class="col-span-4 border-b-2 border-dashed border-black"></div>
        </div>
        <div class="grid grid-cols-6 mb-1 font-semibold">
            <div class="col-span-2"></div>
            <div class="col-span-2" style="font-size: 12px">
                <div class="mr-2">Total</div>
            </div>
            <div class="col-span-2 text-right" style="font-size: 12px">
                <div>{{Intl.NumberFormat('en-US').format(total)}}</div>
            </div>
        </div>
        <div class="grid grid-cols-6 mb-1">
            <div class="col-span-2"></div>
            <div class="col-span-4 border-b-2 border-dashed border-black"></div>
        </div>
        <div class="grid grid-cols-6 italic">
            <div class="col-span-2"></div>
            <div class="col-span-2" style="font-size: 12px">
                <div class="mr-2">Lunas</div>
            </div>
            <div class="col-span-2 text-right" style="font-size: 12px">
                <div>{{Intl.NumberFormat('en-US').format(lunas)}}</div>
            </div>
        </div>
        <div class="mb-1">
            <div class="font-semibold italic" style="font-size: 12px">{{journal}} {{bank}}</div>
        </div>
        <div class="border-t-2 border-b-2 border-black text-center mb-2">
            <div class="font-semibold py-2" style="font-size: 12px">Terimakasih !</div>
        </div>
        <div class="grid grid-cols-7 px-2" style="font-size: 9px">
            <div class="col-span-3">Telp: (022) 7106162</div>
            <div class="col-span-4">Web: http://dutapersada.co.id</div>
        </div>
        <div class="grid grid-cols-7 px-2" style="font-size: 9px">
            <div class="col-span-3">WA: 08112106162</div>
            <div class="col-span-4">Email: info@dutapersada.co.id</div>
        </div>
    </div>
</template>

<script>
import {moment} from '../moment'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            date: '',
            invoice: '',
            user: '',
            customerName: '',
            noTelp: '',
            items: [],
            subtotal: '',
            discount: '',
            total: '',
            lunas: '',
            journal: '',
            bank: '',
        }
    },
    methods: {
        getDate (d) {
            return moment(d)
        }
    },
    watch: {
        data (val) {
            let struk = val.invoice
            let payment = val.payments
            this.date = struk.createdAt
            this.invoice = struk.invoiceNo
            this.user = struk.user.name
            this.customerName = struk.billTo.customer.name 
            this.noTelp = struk.billTo.customer.contact.phone || struk.billTo.customer.contact.mobile
            this.items = struk.items
            this.subtotal = struk.total
            this.discount = struk.discount.amount
            this.total = struk.grandTotal
            this.lunas = struk.grandTotal
            if(payment[0].journal == 'Debit Card') {
                this.journal = 'Debit'
                if(payment[0].bank.name == 'PT. BCA (BANK CENTRAL ASIA) TBK / BCA') {
                    this.bank = 'BCA'
                }
                if(payment[0].bank.name == 'PT. BANK MANDIRI / BANK MANDIRI') {
                    this.bank = 'Mandiri'
                }
            }
            if(payment[0].journal == 'Transfer Bank') {
                this.journal = 'Transfer'
                if(payment[0].bank.name == 'PT. BCA (BANK CENTRAL ASIA) TBK / BCA') {
                    this.bank = 'BCA'
                }
                if(payment[0].bank.name == 'PT. BANK MANDIRI / BANK MANDIRI') {
                    this.bank = 'Mandiri'
                }
            }
            if(payment[0].journal == 'Tokopedia') {
                this.journal = 'Tokopedia'
                this.bank = ''
            }
            if(payment[0].journal == 'Cash') {
                this.journal = 'Cash'
                this.bank = ''
            }
        }
    }
}
</script>